import { sortByField } from "@/lib/aggregates";
import { computed } from "vue";

export function useOptions({
  model: modelRef,
  options: optionsRef,
  textField: textFieldRef,
  valueField: valueFieldRef,
}) {
  const options = computed(() => {
    const textField = textFieldRef.value;
    const valueField = valueFieldRef.value;
    const fieldAdjustedOptions = optionsRef.value.map(option => ({
      text: option[textField],
      value: option[valueField],
    }));
    const sortedOptions = sortByField(fieldAdjustedOptions, { field: "text" });
    return sortedOptions;
  });

  const selected = computed(() => {
    const model = modelRef.value;
    return options.value.filter(({ value }) => model.includes(value));
  });

  const unselected = computed(() => {
    const model = modelRef.value;
    return options.value.filter(({ value }) => !model.includes(value));
  });

  function handleSelected(option) {
    const model = modelRef.value;
    model.push(option.value);
    modelRef.value = model;
  }

  function handleUnselected(option) {
    const model = modelRef.value;
    const index = model.indexOf(option.value);
    model.splice(index, 1);
    modelRef.value = model;
  }

  return { handleSelected, handleUnselected, selected, unselected };
}
