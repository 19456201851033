var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    { staticClass: "list-base" },
    _vm._l(_vm.options, function (option) {
      return _c("li", { key: option.value, staticClass: "list-base-item" }, [
        _c("input", {
          staticClass: "list-base-item-check",
          attrs: { id: option.value, type: "checkbox" },
          domProps: { checked: _vm.status === "selected" },
          on: {
            input: function ($event) {
              return _vm.$emit("toggle", option)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "list-base-item-text", attrs: { for: option.value } },
          [_vm._v("\n      " + _vm._s(option.text) + "\n    ")]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }