var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-lookup", attrs: { id: _setup.parentId } },
    [
      _c(
        _setup.DisplayValue,
        _vm._b(
          { attrs: { selected: _setup.selected } },
          "DisplayValue",
          _setup.anchorProperties,
          false
        )
      ),
      _vm._v(" "),
      _c(
        _setup.DropdownSurface,
        _vm._b(
          { attrs: { size: _vm.size, "anchor-box": _setup.anchorBox } },
          "DropdownSurface",
          _setup.popoverProperties,
          false
        ),
        [
          _c("pre", [_vm._v("busy: " + _vm._s(_vm.busy))]),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("request")
                },
              },
            },
            [_vm._v("\n      more\n    ")]
          ),
          _vm._v(" "),
          _setup.isArrayFull(_setup.selected) ||
          _setup.isArrayFull(_setup.unselected)
            ? _c("hr")
            : _vm._e(),
          _vm._v(" "),
          _c(_setup.ListSelected, {
            attrs: { options: _setup.selected },
            on: { toggle: _setup.handleUnselected },
          }),
          _vm._v(" "),
          _setup.isArrayFull(_setup.selected) &&
          _setup.isArrayFull(_setup.unselected)
            ? _c("hr")
            : _vm._e(),
          _vm._v(" "),
          _c(_setup.ListUnselected, {
            attrs: { options: _setup.unselected, size: _vm.size },
            on: { toggle: _setup.handleSelected },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }