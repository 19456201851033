import { kebabCase } from "@/lib/strings";
import { onBeforeUnmount, onMounted, ref } from "vue";

export function usePopover(parentId) {
  const popoverId = kebabCase(parentId, "popover");
  const anchorId = kebabCase(parentId, "anchor");

  const anchorProperties = {
    id: anchorId,
    popovertarget: popoverId,
  };

  const popoverProperties = {
    id: popoverId,
    popover: "auto",
  };

  const anchorBox = ref({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
  });

  let resizeObserver = null;
  onMounted(() => {
    updateAnchorData();
    resizeObserver = new ResizeObserver(() => updateAnchorData());
    const anchorEl = document.getElementById(anchorId);
    if (anchorEl) {
      resizeObserver.observe(anchorEl);
    }
  });

  onBeforeUnmount(() => {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
  });

  return { anchorBox, anchorProperties, popoverProperties };

  function updateAnchorData() {
    const anchorEl = document.getElementById(anchorId);
    const rect = anchorEl.getBoundingClientRect();
    anchorBox.value = {
      height: rect.height,
      left: rect.left,
      top: rect.top,
      width: rect.width,
    };
  }
}
