var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dropdown-surface",
      style: {
        "--base-size": _vm.size,
        "--anchor-height": `${_vm.anchorBox.height}px`,
        "--anchor-left": `${_vm.anchorBox.left}px`,
        "--anchor-top": `${_vm.anchorBox.top}px`,
        "--anchor-width": `${_vm.anchorBox.width}px`,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }