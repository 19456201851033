<script setup>
import { isArrayFull } from "@/lib/aggregates";
import { kebabCase } from "@/lib/strings";
import { computed, toRefs } from "vue";

import DisplayValue from "./display-value.vue";
import DropdownSurface from "./dropdown-surface.vue";
import ListSelected from "./list-selected.vue";
import ListUnselected from "./list-unselected.vue";
import { useOptions } from "./use-options";
import { usePopover } from "./use-popover";

const props = defineProps({
  busy: {
    default: false,
    type: Boolean,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  id: {
    required: true,
    type: String,
  },
  multi: {
    default: false,
    type: Boolean,
  },
  options: {
    default: () => [],
    type: Array,
  },
  size: {
    default: 5,
    type: Number,
  },
  textField: {
    default: "text",
    type: String,
  },
  value: {
    default: () => [],
    type: Array,
  },
  valueField: {
    default: "value",
    type: String,
  },
});
const emit = defineEmits(["input", "request"]);

const model = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit("input", value);
  },
});

const { options, textField, valueField } = toRefs(props);

const parentId = kebabCase(props.id);
const { anchorBox, anchorProperties, popoverProperties } = usePopover(parentId);
const { handleSelected, handleUnselected, selected, unselected } = useOptions({ model, options, textField, valueField });
</script>
<template>
  <div
    :id="parentId"
    class="input-lookup"
  >
    <DisplayValue
      :selected="selected"
      v-bind="anchorProperties"
    />
    <DropdownSurface
      v-bind="popoverProperties"
      :size="size"
      :anchor-box="anchorBox"
    >
      <pre>busy: {{ busy }}</pre>
      <button @click="$emit('request')">
        more
      </button>
      <hr v-if="isArrayFull(selected) || isArrayFull(unselected)">
      <ListSelected
        :options="selected"
        @toggle="handleUnselected"
      />
      <hr v-if="isArrayFull(selected) && isArrayFull(unselected)">
      <ListUnselected
        :options="unselected"
        :size="size"
        @toggle="handleSelected"
      />
    </DropdownSurface>
  </div>
</template>
<style scoped>
.input-lookup {
  --input-lookup-font-size: var(--w-font-size-30);
}

.input-lookup {
  position: relative;
  width: 100%;

  font-size: var(--input-lookup-font-size);
  font-weight: var(--w-font-weight-20);
}
</style>
